<template>
  <div class="payment-gateway-form">

    <div class="vx-col w-1/4 mb-6">
      <label class="vs-input--label w-full mb-2"> {{$t("shops.select_system")}}</label>
      <v-select
        name="paymentGateway"
        :placeholder="$t('shops.select_a_payment_gateway')"
        class="select-large"
        :value="paymentGatewaySelected"
        :options="paymentGatewayOptions"
        @input="option => selectPaymentGateway(option)"
        v-validate="'required'"
        :class="{'danger-border-select': errorPaymentGateway && errors.has('paymentGateway')}"
        :danger-text="$t('shops.errors.mode_is_required')"
        :danger="errorPaymentGateway && errors.has('paymentGateway')"
      />
    </div>
    <div v-if="paymentGatewaySelected === 'Stripe'" class="vx-row mb-6">
      <div class="vx-col">
        <label class="vs-input--label w-1/2">{{ $t("shops.public_api_key") }}</label>
        <vs-input
          v-model="payment.merchant_id"
          v-validate="'required'"
          class="w-full"
          name="merchant_id"
          :danger="errorMerchantId && errors.has('merchant_id')"
          :danger-text="$t('shops.errors.merchant_id_is_required')"
        />
      </div>
      <div class="vx-col">
          <label class="vs-input--label w-1/2">{{ $t("shops.secret_api_key") }}</label>
          <vs-input
            v-model="payment.token"
            v-validate="'required'"
            class="w-full"
            name="token"
            :danger="errorToken && errors.has('token')"
            :danger-text="$t('shops.errors.token_is_required')"
          />
      </div>
      <div class="vx-col">
          <label class="vs-input--label w-1/2">{{ $t("shops.secret_webhook_key") }}</label>
          <vs-input
            v-model="payment.site"
            class="w-full"
            name="site"
            v-validate="'required'"
            :danger="errorSite && errors.has('site')"
            :danger-text="$t('shops.errors.site_is_required')"
          />
      </div>
    </div>
    <div v-if="paymentGatewaySelected === 'Paypal'" class="vx-row mb-6">
      <div class="vx-col">
        <label class="vs-input--label w-1/2">{{ $t("shops.clientid") }}</label>
        <vs-input
          v-model="payment.merchant_id"
          v-validate="'required'"
          class="w-full"
          name="merchant_id"
          :danger="errorMerchantId && errors.has('merchant_id')"
          :danger-text="$t('shops.errors.merchant_id_is_required')"
        />
      </div>
      <div class="vx-col">
          <label class="vs-input--label w-1/2">{{ $t("shops.clientsecret") }}</label>
          <vs-input
            v-model="payment.token"
            v-validate="'required'"
            class="w-full"
            name="token"
            :danger="errorToken && errors.has('token')"
            :danger-text="$t('shops.errors.token_is_required')"
          />
      </div>
      <div class="vx-col w-1/4">
          <label class="vs-input--label w-1/2">{{ $t("shops.mode") }}</label>
          <v-select
            class="w-full"
            name="mode"
            :placeholder="$t('shops.select_a_mode')"
            :value="modeSelected"
            :options="modeOptions"
            v-validate="'required'"
            :class="{'danger-border-select': errorMode && errors.has('mode')}"
            :danger-text="$t('shops.errors.mode_is_required')"
            :danger="errorMode && errors.has('mode')"
            @input="option => selectMode(option)"
          />
      </div>
    </div>
    <div v-if="paymentGatewaySelected === 'Netaxept'" class="vx-row mb-6">
      <div class="vx-col">
        <label class="vs-input--label w-1/2">{{ $t("shops.merchant_id") }}</label>
        <vs-input
          v-model="payment.merchant_id"
          v-validate="'required'"
          class="w-full"
          name="merchant_id"
          :danger="errorMerchantId && errors.has('merchant_id')"
          :danger-text="$t('shops.errors.merchant_id_is_required')"
        />
      </div>
      <div class="vx-col">
          <label class="vs-input--label w-1/2">{{ $t("shops.token") }}</label>
          <vs-input
            v-model="payment.token"
            v-validate="'required'"
            class="w-full"
            name="token"
            :danger="errorToken && errors.has('token')"
            :danger-text="$t('shops.errors.token_is_required')"
          />
      </div>
      <div class="vx-col w-1/4">
          <label class="vs-input--label w-1/2">{{ $t("shops.mode") }}</label>
          <v-select
            class="w-full"
            name="mode"
            :placeholder="$t('shops.select_a_mode')"
            :value="modeSelected"
            :options="modeOptions"
            v-validate="'required'"
            :class="{'danger-border-select': errorMode && errors.has('mode')}"
            :danger-text="$t('shops.errors.mode_is_required')"
            :danger="errorMode && errors.has('mode')"
            @input="option => selectMode(option)"
          />
      </div>
    </div>
    <div v-if="paymentGatewaySelected === 'Paybox'" class="vx-row mb-6">
      <div class="vx-col">
        <label class="vs-input--label w-1/2">{{ $t("shops.merchant_id") }}</label>
        <vs-input
          v-model="payment.merchant_id"
          class="w-full"
          name="merchant_id"
          v-validate="'required'"
          :danger="errorMerchantId && errors.has('merchant_id')"
          :danger-text="$t('shops.errors.merchant_id_is_required')"
        />
      </div>
      <div class="vx-col">
          <label class="vs-input--label w-1/2">{{ $t("shops.site") }}</label>
          <vs-input
            v-model="payment.site"
            class="w-full"
            name="site"
            v-validate="'required'"
            :danger="errorSite && errors.has('site')"
            :danger-text="$t('shops.errors.site_is_required')"
          />
      </div>
      <div class="vx-col">
          <label class="vs-input--label w-1/2">{{ $t("shops.rank") }}</label>
          <vs-input
            v-model="payment.rank"
            class="w-full"
            name="rank"
            v-validate="'required'"
            :danger="errorRank && errors.has('rank')"
            :danger-text="$t('shops.errors.rank_is_required')"
          />
      </div>
      <div class="vx-col">
          <label class="vs-input--label w-1/2">{{ $t("shops.HMAC_key") }}</label>
          <vs-input
            v-model="payment.token"
            class="w-full"
            name="token"
            v-validate="'required'"
            :danger="errorToken && errors.has('token')"
            :danger-text="$t('shops.errors.HMAC_key_is_required')"
          />
      </div>
      <div class="vx-col w-1/4">
          <label class="vs-input--label w-1/2">{{ $t("shops.mode") }}</label>
          <v-select
            class="w-full"
            name="mode"
            v-validate="'required'"
            :placeholder="$t('shops.select_a_mode')"
            :value="modeSelected"
            :options="modeOptions"
            :class="{'danger-border-select': errorMode && errors.has('mode')}"
            :danger-text="$t('shops.errors.mode_is_required')"
            :danger="errorMode && errors.has('mode')"
            @input="option => selectMode(option)"
          />
      </div>
    </div>
    <div v-if="paymentGatewaySelected === 'Redsys'" class="vx-row mb-6">
      <div class="vx-col">
        <label class="vs-input--label w-1/2">{{ $t("shops.merchant_id") }}</label>
        <vs-input
          v-model="payment.merchant_id"
          class="w-full"
          name="merchant_id"
          v-validate="'required'"
          :danger="errorMerchantId && errors.has('merchant_id')"
          :danger-text="$t('shops.errors.merchant_id_is_required')"
        />
      </div>
      <div class="vx-col">
          <label class="vs-input--label w-1/2">{{ $t("shops.token") }}</label>
          <vs-input
            v-model="payment.token"
            class="w-full"
            name="token"
            v-validate="'required'"
            :danger="errorToken && errors.has('token')"
            :danger-text="$t('shops.errors.token_key_is_required')"
          />
      </div>
      <div class="vx-col">
          <label class="vs-input--label w-1/2">{{ $t("shops.terminal_id") }}</label>
          <vs-input
            v-model="payment.terminal_id"
            class="w-full"
            name="terminal_id"
            v-validate="'required'"
            :danger="errorTerminalId && errors.has('terminalId')"
            :danger-text="$t('shops.errors.terminal_id_is_required')"
          />
      </div>
      <div class="vx-col w-1/4">
          <label class="vs-input--label w-1/2">{{ $t("shops.mode") }}</label>
          <v-select
            class="w-full"
            name="mode"
            v-validate="'required'"
            :placeholder="$t('shops.select_a_mode')"
            :value="modeSelected"
            :options="modeOptions"
            :class="{'danger-border-select': errorMode && errors.has('mode')}"
            :danger-text="$t('shops.errors.mode_is_required')"
            :danger="errorMode && errors.has('mode')"
            @input="option => selectMode(option)"
          />
      </div>
    </div>
    <div v-if="paymentGatewaySelected === 'Paycomet'" class="vx-row mb-6">
      <div class="vx-col">
        <label class="vs-input--label w-1/2">{{ $t("shops.merchant_id") }}</label>
        <vs-input
          v-model="payment.merchant_id"
          class="w-full"
          name="merchant_id"
          v-validate="'required'"
          :danger="errorMerchantId && errors.has('merchant_id')"
          :danger-text="$t('shops.errors.merchant_id_is_required')"
        />
      </div>
      <div class="vx-col">
          <label class="vs-input--label w-1/2">{{ $t("shops.token") }}</label>
          <vs-input
            v-model="payment.token"
            class="w-full"
            name="token"
            v-validate="'required'"
            :danger="errorToken && errors.has('token')"
            :danger-text="$t('shops.errors.token_key_is_required')"
          />
      </div>
      <div class="vx-col">
          <label class="vs-input--label w-1/2">{{ $t("shops.terminal_id") }}</label>
          <vs-input
            v-model="payment.terminal_id"
            class="w-full"
            name="terminal_id"
            v-validate="'required'"
            :danger="errorTerminalId && errors.has('terminalId')"
            :danger-text="$t('shops.errors.terminal_id_is_required')"
          />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div v-for="(language, index) in languages()" :key="index" class="vx-col">
        <label class="vs-input--label w-1/2">{{ $t(`shops.name_${language}`) }}</label>
        <vs-input v-if="langRequired(language)"
          v-model="payment.name"
          v-validate="'required'"
          class="w-full"
          name="name"
          :danger="errorName && errors.has('name')"
          :danger-text="$t('shops.errors.name_is_required')" />
        <vs-input v-else v-model="payment.translations[language].name" class="w-full" :name="`name${language}`" />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div v-for="(language, index) in languages()" :key="index" class="vx-col">
        <label class="vs-input--label w-1/2">{{ $t(`shops.button_${language}`) }}</label>
        <vs-input v-if="langRequired(language)"
          v-model="payment.button"
          v-validate="'required'"
          class="w-full"
          name="button"
          :danger="errorButton && errors.has('button')"
          :danger-text="$t('errors.shops.text_button_is_required')"
        />
        <vs-input v-else v-model="payment.translations[language].button" class="w-full" :name="`button${language}`" />
      </div>
    </div>
    <span class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to">{{errors.first('token')}}</span>
    <div class="vx-col w-1/3">
      <vs-button @click="validateAndSubmit()" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";

export default {
  name: "PaymentGatewayForm",
  props: {
    value: { type: Object, default: null }
  },
  mixins: [ notifications, shopLanguages ],
  data() {
    return {
      paymentGatewayOptions: [
        { label: "Netaxept", value: "Netaxept" },
        { label: "Paybox", value: "Paybox" },
        { label: "Paycomet", value: "Paycomet" },
        { label: "Paypal", value: "Paypal"},
        { label: "Redsys", value: "Redsys" },
        { label: "Stripe", value: "Stripe" },
      ],
      modeOptions: [
        { label: "Test", value: "test" },
        { label: "Real", value: "real" }
      ],
      paymentGatewaySelected: "",
      modeSelected: "",
      errorName: false,
      errorButton: false,
      errorToken: false,
      errorTerminalId: false,
      errorMerchantId: false,
      errorSite: false,
      errorRank: false,
      errorMode: false,
      errorType: false,
      errorPaymentGateway: false,
      payment: {} 
    };
  },

  methods: {
    selectMode(option) {
      this.modeSelected = option === null ? option : option.value;
    },
    selectPaymentGateway(option) {
      this.paymentGatewaySelected = option === null ? option : option.value;
    },
    validateAndSubmit() {
      const payload = { ...this.languages().reduce((carry, lang) => {
          carry[lang] = this.payment.translations[lang]
          return carry
        }, {}), ...this.payment}

      payload.mode = this.modeSelected
      payload.type = this.paymentGatewaySelected.toLowerCase()

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("submit", payload);
        } else {
          this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.payment_gateway_validation_failed"));
          if (!this.payment.name) this.errorName = true;
          if (!this.payment.button) this.errorButton = true;
          if (!this.payment.token) this.errorToken = true;
          if (!this.payment.terminal_id) this.errorTerminalId = true;
          if (!this.payment.merchant_id) this.errorMerchantId = true;
          if (!this.payment.site) this.errorSite = true;
          if (!this.payment.rank) this.errorRank = true;
          if (!this.payment.mode) this.errorMode = true;
          if (!this.payment.type) this.errorType = true;
          if (!this.paymentGatewaySelected) this.errorPaymentGateway = true;
        }
      })
    }
  },
  async created() {
    this.payment = JSON.parse(JSON.stringify(this.value))
    this.paymentGatewaySelected = this.payment.type.charAt(0).toUpperCase() + this.payment.type.slice(1);
    this.modeSelected = this.payment.mode
  }
};
</script>

<style></style>
