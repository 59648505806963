var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paymnet-gateway-form" },
    [
      _c(
        "vx-card",
        { staticClass: "mb-base no-shadow" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              _vm.showForm
                ? _c(
                    "vs-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.onCreate()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("shops.back")))]
                  )
                : _vm._e(),
              !_vm.showForm
                ? _c(
                    "div",
                    { staticClass: "mb-base" },
                    [
                      _c("data-table", {
                        attrs: {
                          data: _vm.tableData,
                          options: _vm.tableOptions
                        },
                        on: {
                          create: _vm.onCreate,
                          remove: function($event) {
                            return _vm.onRemove(
                              $event,
                              "shops.paymentGateways.title"
                            )
                          },
                          update: _vm.onUpdate,
                          sort: _vm.onSort,
                          search: _vm.onSearch,
                          "change-page": _vm.onTablePageChange,
                          "per-page-change": _vm.onPerPageChange
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            _vm.showForm
              ? _c(
                  "div",
                  { staticClass: "mb-base mt-10" },
                  [
                    _c("payment-gateway-form", {
                      on: { submit: _vm.onPaymentGatewaysFormSubmit },
                      model: {
                        value: _vm.formData,
                        callback: function($$v) {
                          _vm.formData = $$v
                        },
                        expression: "formData"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }