<template>
  <div class="paymnet-gateway-form">
    <vx-card class="mb-base no-shadow">
      <transition name="fade" mode="out-in">
        <vs-button v-if="showForm" @click="onCreate()">{{ $t("shops.back") }}</vs-button>

        <div class="mb-base" v-if="!showForm">
          <data-table
            :data="tableData"
            :options="tableOptions"
            @create="onCreate"
            @remove="onRemove($event, 'shops.paymentGateways.title')"
            @update="onUpdate"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
          />
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="mb-base mt-10" v-if="showForm">
          <payment-gateway-form v-model="formData" @submit="onPaymentGatewaysFormSubmit" />
        </div>
      </transition>
    </vx-card>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters, mapActions } from "vuex";
import DataTable from "@/modules/Shared/Components/table/DataTable";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";
import PaymentGatewayForm from "@/modules/Shops/Pages/forms/PaymentGatewaysForm.vue";

export default {
  name: "PaymentGateways",

  mixins: [ notifications, table_functions, shopLanguages ],

  components: {
    DataTable,
    PaymentGatewayForm
  },

  data() {
    return {
      showForm: false,
      formData: {},
      exportPayload: {}
    };
  },

  computed: {
    ...mapGetters("shops/payment_gateways", {
      paymentGatewaysList: "getAll",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      paymentGatewayById: "getById"
    }),

    tableData() {
      return this.paymentGatewaysList.map(this.PaymentGatewaysToTableData);
    },

    tableOptions() {
      return {
        actions: {
          create: true,
          update: true,
          delete: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
      };
    },
  },

  methods: {
    ...mapActions("shops/payment_gateways", {
        loadData: "load",
        createData: "create",
        updateData: "update",
        removeData: "remove"
      }),

    PaymentGatewaysToTableData(paymentGateway) {
      return {
        id: paymentGateway.id,
        name: paymentGateway.name,
        button: paymentGateway.button,
        type: paymentGateway.type,
        mode: paymentGateway.mode
      };
    },

    async createPaymentGateway(data) {
      this.$vs.loading();
      try {
        const paymentGateway = await this.createData(data);
        this.formData = Object.assign({}, this.formData, paymentGateway);
        this.notifySuccess(
          i18n.t("shops.save"),
          `${i18n.t('shops.payment_gateway')} ${this.formData.name} ${i18n.t('shops.has_been_created_successfully')}`
        );
        this.formData = this.default();
        this.showForm = false;
      } catch (error) {
        this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.failed_to_create_a_payment_gateway"));
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async onPaymentGatewaysFormSubmit(paymentGateway) {
      this.$vs.loading();
      try {
        paymentGateway.resort_id = this.$route.params.uuid;
        if (paymentGateway.id === 0) {
          // New object, add to API & store
          await this.createPaymentGateway(paymentGateway);
        } else {
          delete paymentGateway["resorts"]
          // Existing object, update in API & store
          await this.update(paymentGateway);
        }
      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_save_payment_gateway"), error);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async update(paymentGateway) {
      this.$vs.loading();
      try {
        await this.updateData(paymentGateway);

        // Empty form fields
        this.formData = this.default();

        this.showForm = false;
        this.addButton = true;
        this.updateButton = false;

      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_update_payment_gateway"), error);
        this.$vs.loading.close();
      } finally {
        this.notifySuccess(
          i18n.t("shops.update"),
          `${i18n.t("shops.payment_Gateway")} ${paymentGateway.name} ${i18n.t('shops.has_been_updated_successfully')}`
        );
        this.$vs.loading.close();
      }
    },

    async onUpdate(paymentGateway) {
      const translationsDefault = this.translationsDefault(['name', 'button'])

      this.addButton = false;
      this.updateButton = true;

      this.formData = Object.assign({}, this.formData, {
        ...this.paymentGatewayById(paymentGateway.id),
        translations: { ...translationsDefault, ...this.paymentGatewayById(paymentGateway.id).translations }
      });

      this.showForm = true;
    },

    default() {
      return {
        id: 0,
        name: "",
        translations: this.translationsDefault(['name', 'button']),
        type: "",
        token: "",
        merchant_id: "",
        terminal_id: "",
        acquirer_bin: "",
        site: "",
        rank: "",
        mode: ""
      }
    }
  },

  created() {
    this.$store.commit("shops/payment_gateways/RESET_RESOURCE");
  }
};
</script>

<style></style>
